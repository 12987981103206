import { FC } from 'react';

interface Props {
  className?: string;
}

export const RedditIcon: FC<Props> = ({ className }) => (
  <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.16 16-16S24.84 0 16 0Zm6.68 6.32c.92 0 1.667.747 1.667 1.667a1.667 1.667 0 0 1-1.68 1.653 1.653 1.653 0 0 1-1.64-1.573l-3.467-.734-1.067 5c2.427.094 4.64.84 6.227 1.987a2.244 2.244 0 0 1 1.613-.653A2.336 2.336 0 0 1 26.667 16c0 .96-.574 1.773-1.347 2.147.04.226.053.466.053.693 0 3.587-4.173 6.493-9.333 6.493s-9.333-2.906-9.333-6.493c0-.24.013-.493.053-.707A2.341 2.341 0 0 1 5.373 16a2.336 2.336 0 0 1 2.334-2.333c.613 0 1.2.266 1.613.653 1.613-1.173 3.84-1.907 6.32-1.987l1.187-5.573a.47.47 0 0 1 .186-.267c.094-.053.214-.08.32-.053l3.88.827c.254-.587.84-.947 1.467-.947ZM12.333 16a1.667 1.667 0 1 0 .002 3.334A1.667 1.667 0 0 0 12.333 16Zm7.334 0a1.667 1.667 0 1 0 .001 3.335A1.667 1.667 0 0 0 19.667 16Zm-7.294 5.32a.427.427 0 0 0-.307.733c1.12 1.12 3.307 1.214 3.947 1.214.64 0 2.8-.08 3.947-1.214a.484.484 0 0 0 .04-.613.427.427 0 0 0-.613 0c-.734.707-2.24.973-3.347.973-1.107 0-2.64-.266-3.347-.973a.478.478 0 0 0-.32-.12Z"
      fill="#fff"
      fillOpacity=".64"
    />
  </svg>
);
