import { FC } from 'react';

interface Props {
  className?: string;
}

export const GithubIcon: FC<Props> = ({ className }) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.951 0C7.144 0 0 7.343 0 16.396c0 7.242 4.6 13.378 10.862 15.591.783.1 1.077-.402 1.077-.805v-2.816c-4.404 1.006-5.382-2.213-5.382-2.213-.685-1.911-1.762-2.414-1.762-2.414-1.468-1.006.098-1.006.098-1.006 1.566.1 2.446 1.71 2.446 1.71 1.468 2.515 3.72 1.81 4.6 1.408.098-1.106.587-1.81.978-2.213-3.522-.402-7.241-1.81-7.241-8.147 0-1.811.587-3.22 1.663-4.426-.097-.302-.685-2.012.196-4.225 0 0 1.37-.402 4.404 1.71 1.272-.402 2.642-.503 4.012-.503 1.37 0 2.74.201 4.012.503 3.034-2.112 4.404-1.71 4.404-1.71.88 2.213.293 3.923.196 4.325a6.606 6.606 0 0 1 1.663 4.426c0 6.337-3.718 7.645-7.241 8.047.587.503 1.076 1.509 1.076 3.018v4.526c0 .403.294.906 1.077.805C27.498 29.774 32 23.638 32 16.396 31.902 7.343 24.758 0 15.951 0Z"
      fill="#fff"
      fillOpacity={0.64}
    />
  </svg>
);
