import { FC } from 'react';

import { IconProps } from '../../types';

export const LongIcon: FC<IconProps> = props => (
  <svg width={61} height={37} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.163 14V3.818h2.153v8.407h4.365V14H1.163Zm16.904-5.09c0 1.11-.21 2.054-.632 2.833-.417.779-.987 1.374-1.71 1.785-.72.407-1.528.611-2.426.611-.905 0-1.717-.205-2.436-.616-.72-.411-1.288-1.006-1.705-1.785-.418-.779-.627-1.722-.627-2.829 0-1.11.209-2.055.627-2.834.417-.779.986-1.372 1.705-1.78.72-.41 1.531-.616 2.436-.616.898 0 1.707.205 2.426.616.723.408 1.293 1.001 1.71 1.78.421.78.632 1.724.632 2.834Zm-2.183 0c0-.72-.108-1.327-.323-1.82-.212-.494-.512-.869-.9-1.124-.388-.255-.842-.383-1.362-.383-.52 0-.974.128-1.362.383-.388.255-.69.63-.905 1.123-.212.494-.318 1.1-.318 1.82s.106 1.326.318 1.82c.215.494.517.868.905 1.123s.842.383 1.362.383c.52 0 .974-.127 1.362-.383.388-.255.688-.63.9-1.123.215-.494.323-1.1.323-1.82Zm12.294-5.092V14h-1.86l-4.43-6.408h-.074V14h-2.153V3.818h1.89l4.394 6.404h.09V3.818h2.143Zm8.53 3.291a2.23 2.23 0 0 0-.294-.641 1.983 1.983 0 0 0-1.079-.78 2.606 2.606 0 0 0-.76-.105c-.52 0-.978.13-1.372.388-.391.258-.696.635-.915 1.128-.219.491-.328 1.09-.328 1.8s.108 1.313.323 1.81c.215.497.52.877.915 1.138.394.259.86.388 1.397.388.487 0 .903-.086 1.248-.258.348-.176.613-.423.795-.741.186-.318.279-.695.279-1.129l.437.065h-2.625v-1.62h4.26v1.282c0 .895-.188 1.664-.566 2.307a3.864 3.864 0 0 1-1.561 1.481c-.663.345-1.422.517-2.277.517-.955 0-1.793-.21-2.516-.631a4.382 4.382 0 0 1-1.69-1.805c-.401-.782-.602-1.71-.602-2.784 0-.825.12-1.561.358-2.207.242-.65.58-1.2 1.014-1.65a4.37 4.37 0 0 1 1.517-1.03 4.914 4.914 0 0 1 1.874-.353c.577 0 1.114.084 1.61.254.498.165.939.4 1.323.705.388.305.705.668.95 1.09a3.7 3.7 0 0 1 .472 1.381h-2.187Z"
      fill="#00FF0A"
    />
    <path d="M20 25.882 29.09 35 60 4" stroke="#00FF0A" strokeWidth={2} />
    <path d="M60 15V4H49" stroke="#00FF0A" strokeWidth={2} />
    <path fill="#00FF0A" d="M35 19h4v2h-4zm-8 0h4v2h-4zm-8 0h4v2h-4zm38 0h4v2h-4zm-46 0h4v2h-4zm38 0h4v2h-4z" />
  </svg>
);
