import { FC } from 'react';

import { IconProps } from './svg-props';

export const FeedbackIcon: FC<IconProps> = ({ ...props }) => (
  <svg
    aria-hidden="true"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z"
      stroke="#fff"
      strokeWidth={1}
    />
  </svg>
);
