import { FC } from 'react';

interface Props {
  className?: string;
}

export const DiscordIcon: FC<Props> = ({ className }) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.169 16-16S24.831 0 16 0Zm10.907 21.193a18.034 18.034 0 0 1-5.574 2.852 13.548 13.548 0 0 1-1.19-1.968c.653-.248 1.29-.554 1.885-.917a9.829 9.829 0 0 1-.32-.25 23.935 23.935 0 0 0-.143-.114c-3.58 1.695-7.516 1.695-11.138 0-.149.124-.306.248-.463.364.595.355 1.224.661 1.877.918a14.316 14.316 0 0 1-1.19 1.968c-1.82-.563-3.664-1.431-5.566-2.853-.388-4.176.389-8.41 3.266-12.75A17.67 17.67 0 0 1 12.9 7.011c.199.355.43.827.587 1.207a16.584 16.584 0 0 1 5.036 0c.157-.38.389-.852.579-1.207a17.61 17.61 0 0 1 4.54 1.43c2.488 3.713 3.729 7.914 3.266 12.75Zm-7.252-7.07c-1.108 0-1.976 1.017-1.976 2.25 0 1.231.893 2.248 1.976 2.248 1.108 0 1.977-1.017 1.977-2.249.024-1.232-.869-2.249-1.977-2.249Zm-9.286 2.25c0-1.233.868-2.25 1.976-2.25 1.1 0 1.993 1.017 1.976 2.25 0 1.231-.868 2.248-1.976 2.248-1.083 0-1.976-1.017-1.976-2.249Z"
      fill="#fff"
      fillOpacity={0.64}
    />
  </svg>
);
