export type address = string;

export enum Side {
  LONG = 0,
  SHORT = 1
}
export enum Dir {
  AddToAmm = 0,
  RemoveFromAmm = 1
}
